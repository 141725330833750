<template>
    <div class="publicite-carre-container">
       <h4 class="tempText">Emplacement réservé à la publicité</h4>
    </div>
</template>

<script>


export default {
    name: "PubliciteCarre",
};
</script>

<style lang="scss">
    @import "publicite-carre.scss";
</style>