<template>
  <div class="description-container p-3">
    <header class="mb-2">
      <h2 class="">{{ getTitre() }}</h2>
      <p class="pink-price">{{ getPrix() }} €</p>
    </header>
    <div class="annonce-header">
      <div class="header-left">
        <p class="publish-date">Publié le {{ getDate() | formatDate }}</p>
        <!--<p class="location">{{getDepartement()}}, {{getRegion()}}</p>-->
      </div>
    </div>
    <div class="annonce-content mb-3">
      <p class="annonce-label mt-2 mb-2 pink">Description</p>
      <p>
        {{ getDesc() }}
      </p>
    </div>
    <div v-if="annonce.cat != 10">
      <p class="annonce-label mt-3 mb-2 pink">Critères</p>

      <table>
        <tr>
          <td><p v-show="getBrand()" class="mr-2">Marque</p></td>
          <td>
            <p v-show="getBrand()">{{ getBrand() }}</p>
          </td>
        </tr>
        <tr>
          <td><p class="mr-2">État</p></td>
          <td>
            <p>{{ getEtat() }}</p>
          </td>
        </tr>
        <tr>
          <td><p class="mr-2">Facture</p></td>
          <td>
            <span v-if="getFact()">Oui</span>
            <span v-if="getFact() == false">Non</span>
          </td>
        </tr>
        <tr>
          <td><p class="mr-2">Garantie</p></td>
          <td>
            <span v-if="getGaranty()">Oui</span>
            <span v-if="getGaranty() == false">Non</span>
          </td>
        </tr>
        <tr>
          <td><p class="mr-2">Envoi possible</p></td>
          <td>
            <span v-if="getEnvoi()">Oui</span>
            <span v-if="getEnvoi() == false">Non</span>
          </td>
        </tr>
      </table>

      <!-- Brand display -->
      <!-- <div class="brand-container d-flex">
            <p v-show="getBrand()" class="mr-2">Marque </p>
            
        </div>

        <div class="condition-container d-flex">
            <p class="mr-2">État </p>
            <p>{{getEtat()}}</p>
        </div>

        <div class="invoice-container d-flex">
                <p class="mr-2">Facture </p>
                <span v-if="getFact()">Oui</span>
                <span v-if="getFact() == false">Non</span>
        </div>
        <div class="guarantee-container d-flex">
            <p class="mr-2">Garantie </p>
            <span v-if="getGaranty()">Oui</span>
            <span v-if="getGaranty() == false">Non</span>
        </div>
            <div class="guarantee-container d-flex">
            <p class="mr-2">Envoi possible </p>
            <span v-if="getEnvoi()">Oui</span>
            <span v-if="getEnvoi() == false">Non</span>
        </div> -->
    </div>
  </div>
</template>
<script>
import Const from "../../../../../model/Const.js";
export default {
  name: "Description",
  props: ["annonce"],
  methods: {
    getTitre() {
      if (this.annonce == undefined) return "";
      return this.annonce.titre;
    },

    getDesc() {
      if (this.annonce == undefined) return "";
      return this.annonce.desc;
    },

    getDate() {
      if (this.annonce == undefined) return "";
      return this.annonce.date;
    },

    getPrix() {
      if (this.annonce == undefined) return 0;
      return this.annonce.prix;
    },

    getDepartement() {
      if (this.annonce == undefined) return "";
      let dept = Const.getDepartementByCode(this.annonce.dept);
      if (dept == undefined)
        return "Code departement indefini : " + this.annonce.dept;
      return dept.text;
    },

    getRegion() {
      if (this.annonce == undefined) return "";
      let reg = Const.getRegionByCode(this.annonce.region);
      if (reg == undefined)
        return "Code region indefini : " + this.annonce.region;
      return reg.text;
    },

    getCategory() {
      if (this.annonce == undefined) return "";

      let cat = Const.getCategoryByValue(this.annonce.cat);
      if (cat == undefined)
        return "Code categorie indefini : " + this.annonce.cat;
      return cat.text;
    },

    getCategoryValue() {
      if (this.annonce == undefined) return 0;
      return this.annonce.cat;
    },

    getBrand() {
      if (this.annonce == undefined) return "";
      return Const.getBrandNameByValue(this.annonce.brand);
    },

    getEtat() {
      let etat = Const.getEtatByCode(this.annonce.etat);
      if (etat == undefined) return Const.getEtatByCode(3).text;
      return etat.text;
    },

    getFact() {
      if (this.annonce == undefined) return undefined;
      return this.annonce.fact;
    },

    getGaranty() {
      if (this.annonce == undefined) return undefined;
      return this.annonce.garanty;
    },

    getEnvoi() {
      if (this.annonce == undefined) return undefined;
      return this.annonce.nocom;
    },
  },
};
</script>

<style lang="scss">
@import "description.scss";
</style>