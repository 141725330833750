<template>
    <div class="simpleHereMap" id="simpleHereMapContainer" ref="simpleHereMap"></div>
</template>
<script>

export default {
    name: "SimpleHereMap",
    props:['positionGiver'],
    data() {
        return {
            platform: null,
           // apikey: "lR-dG6knhDPpCxzqigbVFdn0sqp2ZmeS2Ulw4C1Y4vg",
            apikey: "1uDHyVL9L1OoWjbJFJ8BTWlMLqEpprJp-HE5nP6NS3Y",
            map :null,
        }
    },
    async mounted() {
        const platform = new window.H.service.Platform({
            apikey: this.apikey
        });
        this.platform = platform;
        this.initializeHereMap();
    },
    methods: {
        initializeHereMap() { 
            // rendering map
            const mapContainer = this.$refs.simpleHereMap;
            const H = window.H;
            // Obtain the default map types from the platform object
            let maptypes = this.platform.createDefaultLayers();
            
            // Instantiate (and display) a map object:
            this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 14,
                 center: this.positionGiver(),
                 mapTypeId: "satellite",
            });

            addEventListener("resize", () => this.map.getViewPort().resize());
             // add behavior control
            new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
            // add UI
            let ui = H.ui.UI.createDefault(this.map, maptypes);
            var mapSettings = ui.getControl('mapsettings');
            var zoom = ui.getControl('zoom');
            var scalebar = ui.getControl('scalebar');

            mapSettings.setAlignment('right-middle');
            mapSettings.setVisibility(false);
            zoom.setAlignment('right-middle');
            scalebar.setAlignment('right-middle');
        },
        setCenter(c) {
          if (c != undefined){
             this.map.setCenter(c,false);
          }
        },
        refresh(){
            this.map.getViewPort().resize(); 
        },
    }
};
</script>

<style lang="scss">
    @import "simple-here-map.scss";
</style>