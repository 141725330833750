<template>
    <div v-if="favorite != undefined">
        <img @click="favouriteSwitch($event, favorite)" v-if="!favorite" src="@/assets/icons/0141-heart.svg" alt="coeur vide">
        <img @click="favouriteSwitch($event, favorite)" v-else src="@/assets/icons/0141-heart-full.svg" alt="coeur remplis">
    </div>    
    <div v-else>
        <img @click="youHaveToConnect()" src="@/assets/icons/0141-heart.svg" alt="coeur vide">
         <ModalInfoComponent id="connectezVousModal" ref="connectezVousModal" :modalMessage="connectezVousMsg" :okFunc="okConnectezVous" fermerTitle="Connectez-vous"/>
    </div>  
    
</template>
<script>
import FavoritesService from '../../../../../services/FavoritesService.js'
import AuthService from '../../../../../services/AuthService.js'
import {ToastMixin} from '../../../../../mixins/toast-mixin.js'
import ModalInfoComponent from '../../../../shared-component/modal-info-component/modal-info-component.vue'
import router from '../../../../../router'

export default {
    name: "FavoriteButton",
    props: ['annonce'],
     mixins: [ToastMixin],
    components: {
         ModalInfoComponent
     },
    data: () => {
        return {
            favorite:undefined,
            connectezVousMsg:"Connectez-vous pour accéder à toutes les fonctionnalités.",
        }
    },
    mounted(){
        this.$root.$on('new_favorite_state_from_nav_bar',(newState) =>{
            this.favorite = newState;
        });

        if (!AuthService.isLogged() || AuthService.getCurrentUserId() == this.annonce.userId){
            this.favorite = undefined;
        } else {
            this.updateFavoriteState();
        }
    },
     
    methods :{
        youHaveToConnect(){
             this.$refs.connectezVousModal.showModal();
        },

        // Switch for favorite / unfavorite ad
        favouriteSwitch(event, favorite) {
        
            if (!favorite){
                FavoritesService.addToFavorite(this.annonce).then(()=> this.updateFavoriteState()).then(()=>this.popToast(this.adFavoriteMessage, 'addition-toast'));
            } else {
                FavoritesService.deleteFavorite(this.annonce).then(()=> this.updateFavoriteState()).then(()=>this.popToast(this.adUnfavoriteMessage, 'deletetion-toast'));
            }

            favorite = !favorite
            console.log('fav in ad', favorite)
            console.log('ad index in ad', this.annonce.firestoreId)
       

            // Cancel the clickable link for ad
            event.preventDefault()
            this.$emit('favoriteState', favorite, this.annonce.firestoreId)
            this.$root.$emit('new_favorite_state_from_ad_page',favorite);
        },

        updateFavoriteState(){
            FavoritesService.isFavorite(this.annonce).then((b)=>{
                this.favorite = b;
            },() =>{
                this.favorite = undefined;
            });
         },
         okConnectezVous(){
            console.info("okConnectezVous()");
            router.push('/connexion?ad='+this.$route.params.adId);
        },
    }
}
</script>

<style lang="scss">
    @import "favoriteButton.scss";
</style>