<script>
import CarouselAdComponent from './components/carousel-ad-component/carousel-ad-component.vue'
import SoldByComponent from '../../shared-component/sold-by-component/sold-by-component.vue'
import HereMap from '../../shared-component/here-map-component/here-map-component.vue'
import AnnoncesService from '../../../services/AnnoncesService.js'
import StorageService from '../../../services/StorageService.js'
import AuthService from '../../../services/AuthService.js'
import UsersService from '../../../services/UsersService.js'
import SimpleHereMap from '../../shared-component/simple-here-map/simple-here-map.vue'
import PubliciteCarre from '../../shared-component/publicite-carre/publicite-carre.vue'
import PubliciteRectangle from '../../shared-component/publicite-rectangle/publicite-rectangle.vue'

import Description from './components/description/description.vue'
import FavoriteButton from './components/favoriteButton/favoriteButton.vue'
import ImagesViewer from './components/imagesViewer/imagesViewer.vue'
import Const from "../../../model/Const.js"
import ModalAlertComponent from '../../shared-component/modal-alert-component/modal-alert-component.vue'
import router from '../../../router'

export default {
    name: "AdPage",
    components: {
        CarouselAdComponent,
        SoldByComponent,
        HereMap,
        SimpleHereMap,
        Description,
        FavoriteButton,
        PubliciteCarre,
        PubliciteRectangle,
        ImagesViewer,
        ModalAlertComponent
    },
    props: ['adId','templateMode'],
    data: () => {
        return {
            amoi:false,
            annonce:undefined,
            center:{ 
                lat: 48.844972, 
                lng: 2.401959
            },
            images: undefined,
            seller:'',
            tel:undefined,
           
        }
    },
    created() {
        this.$emit('navbarState', false)
        this.$emit('headerState', 2);
       

        AnnoncesService.getAnnonce(this.adId).then((annonce)=>{
            this.annonce = annonce;
            if (AuthService.isLogged() && AuthService.getCurrentUserId() == annonce.userId){
                this.amoi = true;
            } else {
                this.amoi = false;
            }
            if (!this.annonce.notel){
                this.tel = this.annonce.tel;
            }

            UsersService.getSingleUser(annonce.userId).then((user)=>{
                 console.info("nom = " + user.nom);
                this.seller = user;
               
                },
                (error)=>{
                    console.error("erreur recuperation utilisateur " + annonce.userId)
                    console.error(error);

                });
            
            
            this.center = { 
                lat: annonce.gpslat, 
                lng: annonce.gpslng
            };

            let imagesCount = annonce.getImagesCount();
            this.images = [];
            if (imagesCount>0){
                for(let i = 1 ; i <=imagesCount ; i++){
                    StorageService.getDownloadURL(annonce,i).then((url)=>{
                        this.images.push(url);
                    });
                }
            } else {
                //this.images.push(this.ad_image);
            }
            
            this.$refs.map.setCenter(this.center);
   
        })
    },
  
    destroyed() {
        this.$emit('navbarState', true)
    },
     methods: {
         clickSuppressionAnnonce(){
            this.$refs.modalAlertDelete.showModal()
        },
        cancelDeleteFunc(){
            console.info("click sur Cancel : suppression d'annonce");
        },
        okDeleteFunc(){
            console.info("click sur Ok");
            AnnoncesService.deleteAnnonce(this.$route.params.adId).then(()=>{
                console.info("annonce effacee");
                 router.push('/monCompte/mesAnnonces');
            });
        },
         clickContactPhone(){
             this.$root.$emit('contact_action',1);
         },
         clickContactEmail(){
             this.$root.$emit('contact_action',2);
         },

         positionGiver(){
             return this.center;
         },
         getImages(){
             return this.images;
         },
         getCurrentCarouselIndex(){
             let index = this.$refs.CarouselAd.getCurrentIndex()+1;
             console.info("current carousel index : " + index)
             return index;
         },
         getDepartement(){
            if (this.annonce == undefined) return "";
            let dept = Const.getDepartementByCode(this.annonce.dept);
            if (dept == undefined) return "Code departement indefini : " + this.annonce.dept;
            return dept.text;
        },

        getRegion(){
            if (this.annonce == undefined) return "";
           let reg = Const.getRegionByCode(this.annonce.region);
            if (reg == undefined) return "Code region indefini : " + this.annonce.region;
            return reg.text;
        },
        
     }
};
</script>

<style lang="scss">
    @import "ad-page.scss";
</style>
<template src="./ad-page.html"></template>