<template>
    <div class="pubParent">
        <div class="publicite-rectangle-container">
            <h4 class="tempText">Emplacement réservé à la publicité</h4>
        </div>
    </div>
  
</template>

<script>


export default {
    name: "PubliciteRectangle",
};
</script>

<style lang="scss">
    @import "publicite-rectangle.scss";
</style>