<template>
    <div v-if="pageNumber" class="ImagesViewerContainer">
        <img width="100%" class="ImagesViewerImage" :src="images[pageNumber-1]"/>
        <div class="ImagesViewerPaginationNavContainer">
            <!-- <b-pagination-nav 
             :hide-goto-end-buttons="true"
                v-model="pageNumber"
                :number-of-pages="images.length"
                base-url="#"
                first-number
                size="lg"/>-->
             
            <label v-if="images.length >1" class="container">
            <input type="radio" checked="checked" name="radio" v-model="pageNumber" value="1">
            <span class="checkmark"></span>
            </label>
            <label v-if="images.length >1" class="container">
            <input type="radio" name="radio" v-model="pageNumber" value="2">
            <span class="checkmark"></span>
            </label>
            <label v-if="images.length >2" class="container">
            <input type="radio" name="radio" v-model="pageNumber" value="3">
            <span class="checkmark"></span>
            </label>
            <label v-if="images.length >3" class="container">
            <input type="radio" name="radio" v-model="pageNumber" value="4">
            <span class="checkmark"></span>
            </label>
        </div>
       
    </div>
    
</template>
<script>

export default {
    name: "ImagesViewer",
    props: ['images','currentIndex'],
    data: () => {
        return {
           pageNumber: undefined,
        }
    },
    created(){
        this.pageNumber = this.currentIndex;
    }
  
}
</script>

<style lang="scss">
    @import "imagesViewer.scss";
</style>