<template>
    <div class="carousel-ad-container mb-4">

        <div v-if="imagesAvailable()">
            <b-carousel
       
                v-model='value'
                ref="myCarousel"
                class="myCarousel"
                id="carousel-no-animation"
                style="text-shadow: 0px 0px 2px #000; outline: none"
                no-animation
                :indicators="images.length > 1">   
                <template v-for="(image, index) in images" >
                    <a v-if="imagesAvailable()" @click="toggleSlideBarImage()" href="javascript:void(0);" :key="index">
                        <b-carousel-slide :img-src="image" :key="index"></b-carousel-slide>
                    </a> 
                   
                </template>
            </b-carousel>
             
        </div> 


        <div v-if="!imagesAvailable()">
            <b-carousel
                v-model='value'
                ref="myCarousel"
                class="myCarousel"
                id="carousel-no-animation"
                style="text-shadow: 0px 0px 2px #000; outline: none"
                no-animation
                >   
                <template>
                    <b-carousel-slide :img-src="ad_image"></b-carousel-slide>
                </template>
                        
            </b-carousel>
             
        </div> 
    </div>
</template>

<script>

export default {
    name: "CarouselAdComponent",
    props: ['images'],
    data: () => {
        return {
           value: 0,
           ad_image:require(`../../../../../assets/new_logo.jpeg`),
        }
    },
    methods : {
        imagesAvailable(){
            return this.images != undefined && this.images.length > 0;
        },
        getCurrentIndex(){
            return this.value;
        },
        toggleSlideBarImage(){
            console.info("toggle sidebar-2");
            this.$root.$emit('bv::toggle::collapse', 'sidebar-2');
        }

    }
}
</script>

<style lang="scss">
    @import "carousel-ad-component.scss";
</style>